<template>
  <div>
    <vs-popup class="sm:popup-w-80" title="Tambah Rekanan" :active="isActive" v-on:update:active="emitModalIsActive">

      <div>
        <div class="vx-row">
          <div class="vx-col w-full">
            <ValidationErrors :errors="errors"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/3 w-full -mt-1.5">
            <label class="ml-1 text-xs opacity-75 -mt-1">Jenis Rekanan *</label>
            <vx-input-group>
              <vs-input placeholder="Pilih Rekanan" :value="data.jenis_rekanan.nama" @click="isModalJenisRekananActive = true" readonly/>
              <template slot="append">
                <div class="append-text btn-addon">
                  <vs-button type="filled" icon-pack="feather" icon="icon-search" @click="isModalJenisRekananActive = true"/>
                </div>
              </template>
            </vx-input-group>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <vs-input class="w-full" label-placeholder="Kode - Generate Otomatis" v-model="data.kode" disabled/>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <vs-input class="w-full" label-placeholder="Nama" v-model="data.nama"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input class="w-full" icon-pack="feather" icon="icon-phone" label-placeholder="Telp *" v-model="data.telp"/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input class="w-full" icon-pack="feather" icon="icon-mail" label-placeholder="E-mail *" v-model="data.email"/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input class="w-full" icon-pack="feather" icon="icon-printer" label-placeholder="Fax" v-model="data.fax"/>
          </div>
          <div class="vx-col sm:w-1/4 w-full">
            <vs-input class="w-full" icon-pack="feather" icon="icon-user" label-placeholder="CP" v-model="data.cp"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/2 w-full">
            <vs-input class="w-full" label-placeholder="No. KTP" v-model="data.no_ktp"/>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <vs-input class="w-full" label-placeholder="Jabatan" v-model="data.jabatan"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-6/12 w-full">
            <vs-input class="w-full" label-placeholder="NPWP" v-model="data.npwp"/>
          </div>
          <div class="vx-col sm:w-6/12 w-full">
            <vs-input class="w-full" label-placeholder="NPPKP" v-model="data.nppkp"/>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <vs-input class="w-full" icon-pack="feather" icon="icon-map-pin" label-placeholder="Alamat" v-model="data.alamat"/>
          </div>
        </div>

        <vs-divider> Akun Bank</vs-divider>

        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/3 w-full">
            <vs-input class="w-full" label-placeholder="Bank *" v-model="data.bank_rekening"/>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <vs-input class="w-full" label-placeholder="No. Rekening *" v-model="data.no_rekening"/>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <vs-input class="w-full" label-placeholder="A/N Rekening *" v-model="data.an_rekening"/>
          </div>
        </div>

        <vs-divider> User Login</vs-divider>

        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/3 w-full">
            <vs-input class="w-full" icon-pack="feather" icon="icon-user" label-placeholder="Username" autocomplete="new-password"
                      v-model="data.username"/>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <vs-input type="password" class="w-full" icon-pack="feather" icon="icon-lock" label-placeholder="Password" autocomplete="new-password"
                      v-model="data.password"/>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <vs-input type="password" class="w-full" icon-pack="feather" icon="icon-lock"
                      label-placeholder="Confirm Password" v-model="data.confirm_password"/>
          </div>
        </div>
        <div class="vx-row float-right mt-6">
          <div class="vx-col w-full">
            <vs-button class="mr-3" type="border" @click="emitModalIsActive(false)">Batal</vs-button>
            <vs-button :disabled="isLoading" @click="save">
              <span v-if="isLoading" class="animate-pulse">Menyimpan...</span>
              <span v-if="!isLoading">Simpan</span>
            </vs-button>
          </div>
        </div>
      </div>

      <!--modals-->
      <vs-popup class="sm:popup-w-50 popup-content-no-padding"
                title="Pilih Data Jenis Rekanan"
                :active="isModalJenisRekananActive"
                v-on:update:active="isModalJenisRekananActive = $event">
        <JenisRekanan :selectable="true" @selected="onSelectedModalJenisRekanan"/>
      </vs-popup>
    </vs-popup>
  </div>
</template>

<script>
import RekananRepository from '@/repositories/master/rekanan-repository'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import JenisRekanan from '@/views/pages/master/jenis-rekanan/JenisRekanan'
import _ from 'lodash'

export default {
  name: 'RekananAdd',
  components: {
    JenisRekanan,
    ValidationErrors
  },
  props: ['isActive'],
  data () {
    return {
      isModalJenisRekananActive: false,
      isLoading: false,
      errors: null,
      data: {
        jenis_rekanan: {}
      }
    }
  },
  watch: {
    'data.jenis_rekanan' (value) {
      this.data.id_jenis_rekanan = value.id
    }
  },
  methods: {
    onSelectedModalJenisRekanan (item) {
      this.data.jenis_rekanan = item
      this.isModalJenisRekananActive = false
    },

    save () {
      this.errors = null
      this.isLoading = true

      RekananRepository.create(this.data)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.isLoading = false
        })
    },

    onSuccess () {
      this.notifySuccess('Data berhasil disimpan.')
      this.resetData()
      this.emitIsSuccess(true)
      this.emitModalIsActive(false)
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, [])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
